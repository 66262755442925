import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Tooltip, Button, Form, Icon, Input, Table, message } from 'antd';
import PropTypes from 'prop-types';

import { ApiContext } from '../services/HubApi';

const { Column } = Table;
const ButtonGroup = Button.Group;

function EditAccount(props) {

    const api = useContext(ApiContext);
    const [emptyState] = useState({
        accountID: '',
        accountName: '',
        description: '',
        erpId: 'direct',
        erpCustomerId: '',
        userEmail: '',
        userEmail2: '',
        userName: ''
    });
    const [formData, setFormData] = useState(emptyState);
    const [isUpdating, setIsUpdating] = useState(false);
    const [mode, setMode] = useState('Edit');

    useEffect(() => {
        if (props.mode === 'Add') {
            setMode(props.mode);
            setFormData(emptyState);
        } else if (props.mode === 'Edit') {
            setMode(props.mode);
            setFormData({ ...props.editting, userEmail: '', userEmail2: '', userName: '' });
        } else if (props.mode === 'View') {
            setMode(props.mode);
            setFormData({ ...props.editting, userEmail: '', userEmail2: '', userName: '' });
        }
    }, [props, emptyState]);

    useEffect(() => {
        return ()  => {
            //console.log("EditAccount cleanup")
        };
    }, []);

    async function onSubmit(event) {
        event.preventDefault();

        setIsUpdating(true);

        try {
            if (props.mode === 'Add') {
                const newAccount = await api.createAccount(formData.accountName, formData.description, formData.erpId, formData.erpCustomerId);
                await api.createUser(newAccount.accountID, formData.userEmail, formData.userName, 'admin', []);
            
                message.success('New account and user created');
            }
            else if (props.mode === 'Edit') {
                await api.editAccount(formData.accountID, formData.accountName, formData.description, formData.erpId, formData.erpCustomerId);
            
                message.success('Account edited');
            }
        }
        catch (error) {
            alert(error);
        }

        setIsUpdating(false);
        setFormData(emptyState);
        await props.reList();
    }

    async function onCancel(event) {
        event.preventDefault();
        setIsUpdating(false);
        setFormData(emptyState);
        setMode('Add');
    }

    async function onChange(event) {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    function validate() {
        if (formData.accountName === undefined) { return false; }
        if (formData.description === undefined) { return false; }
        if (formData.erpId === undefined) { return false; }
        if (formData.erpCustomerId === undefined) { return false; }

        if (mode === 'Add') {
            return  formData.accountName.length > 0 &&
                    formData.description.length > 0 &&
                    formData.erpId.length > 0 &&
                    formData.erpCustomerId.length > 0 &&
                    formData.userEmail.length > 0 &&
                    formData.userEmail === formData.userEmail2 &&
                    formData.userName.length > 0;
        }
        if (mode === 'Edit') {
            return formData.accountName.length > 0 && formData.description.length > 0 && formData.erpId.length > 0 && formData.erpCustomerId.length > 0;
        }
        return false;
    }

    const formItemLayout =
    {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
    };

    const buttonItemLayout = {
        wrapperCol: { span: 14, offset: 4 },
    };

    return (
        <div>
            <h3>{mode} Account</h3>
            <Form onSubmit={onSubmit} {...formItemLayout} className='edit-account-form'>
                <Form.Item label="Internal Account ID (read-only)">
                    <Input
                        name="accountId"
                        disabled={true}
                        onChange={onChange}
                        placeholder="Internal Account ID"
                        value={formData.accountID}
                    />
                </Form.Item>
                <Form.Item label="Account Name">
                    <Input
                        name="accountName"
                        onChange={onChange}
                        placeholder="Unique customer account"
                        value={formData.accountName}
                    />
                </Form.Item>
                <Form.Item label="Description">
                    <Input
                        name="description"
                        onChange={onChange}
                        placeholder="Description"
                        value={formData.description}
                    />
                </Form.Item>
                <Form.Item label="ERP System">
                    <Input
                        name="erpId"
                        disabled={true}
                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="ERP System"
                        value={formData.erpId}
                    />
                </Form.Item>
                <Form.Item label="ERP Customer ID">
                    <Tooltip title="This is only editable for direct sales customers">
                        <Input
                            name="erpCustomerId"
                            disabled={formData.erpId !== 'direct'}
                            onChange={onChange}
                            placeholder="Unique identifier within the ERP system"
                            value={formData.erpCustomerId}
                        />
                    </Tooltip>
                </Form.Item>
                {(mode === 'Add') ?
                    <React.Fragment>
                        <Alert
                            key="validation"
                            type="warning"
                            closable
                            showIcon
                            message="Double check the email address, the user will have to respond to an email to validate their account"
                        />
                        <Form.Item label="Initial admin - Email address">
                            <Input
                                name="userEmail"
                                onChange={onChange}
                                placeholder="Email address for initial account administrator"
                                value={formData.userEmail}
                            />
                        </Form.Item>
                        <Form.Item label="Initial admin - Confirm Email address">
                            <Input
                                name="userEmail2"
                                onChange={onChange}
                                placeholder="Confirm the email address"
                                value={formData.userEmail2}
                            />
                        </Form.Item>
                        <Form.Item label="Initial admin - Name">
                            <Input
                                name="userName"
                                onChange={onChange}
                                placeholder="Name of initial account administrator"
                                value={formData.userName}
                            />
                        </Form.Item>
                    </React.Fragment>
                    : <div />}
                {(mode !== 'View') ?
                    <Form.Item {...buttonItemLayout}> 
                        <ButtonGroup>
                            <Button onClick={onCancel}>Cancel</Button>
                            <Button type='primary' htmlType='submit' disabled={!validate()} loading={isUpdating} >
                                {mode === 'Add' ? 'Add Account' : 'Save Changes'}
                            </Button>
                        </ButtonGroup>
                    </Form.Item>
                    : <div />}
            </Form>
        </div>
    );
}

EditAccount.propTypes = {
    mode: PropTypes.string,
    editting: PropTypes.object,
    reList: PropTypes.func  
};


export default function AccountMgmt(props) {
    const api = useContext(ApiContext);

    const [ accounts, setAccounts ] = useState([]);
    const [ isLoading, setLoading ] = useState(false);

    const [emptyState] = useState({
        valid: false,
        accountName: '',
        description: '',
        erpId: '',
        erpCustomerId: ''
    });
    const [ editting, setEditting ] = useState(emptyState);

    const onLoad = useCallback(async () => {
        async function loadAccountIds() {
            try {
                const accountArray = await api.getAccounts();

                const accountObjects = accountArray.reduce((arr, obj) => {
                    arr.push({ key: obj, loading: true });

                    return arr;
                }, []);

                return accountObjects;
            }
            catch (error) {
                console.error(error);
                //alert(error);
            }
        }

        async function loadAccountData(id) {
            try {
                let accountData = await api.getAccount(id);
                accountData['key'] = id;
                accountData['loading'] = false;

                return accountData;
            }
            catch (error) {
                console.error(error);
                //alert(error);
            }
        }

        setLoading(true);

        let accountIds = await loadAccountIds();

        let newAccounts = [];

        for (let i = 0; i < (accountIds ? accountIds.length : 0); ++i) {
            const accountId = accountIds[i].key;
            newAccounts[i] = await loadAccountData(accountId);
        }

        setAccounts(newAccounts);
        setEditting(emptyState);

        setLoading(false);
    }, [api, emptyState]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    useEffect(() => {
        return ()  => {
            //console.log("AccountMgmt cleanup")
        };
    }, []);

    async function reList() {
        // Used when the accounts are added/editted
        //onLoad()
        // Trigger a redraw from the top-level
        await props.appStateChange({ ...props.appState, redrawEvent: props.appState.redrawEvent + 1 });
    }

    async function onEdit(id, event) {
        event.preventDefault();

        for (let i = 0; i < accounts.length; ++i) {
            if (accounts[i].key === id)
            {
                setEditting({ ...accounts[i], valid: true });
            }
        }
    }

    return (
        <div>
            <Table
                bordered
                dataSource={accounts}
                loading={isLoading}
                rowKey={record => record.key}
            >
                <Column title='Name' dataIndex='accountName' />
                <Column title='Description' dataIndex='description' />
                <Column title='ERP System' dataIndex='erpId' />
                <Column title='ERP Customer ID' dataIndex='erpCustomerId' />
                <Column title='Action' dataIndex=''
                    render={(text, record) => (
                        (props.readOnly) ?
                            <Button onClick={(event) => { onEdit(record.key, event); }}> Details </Button>
                            :
                            <div>
                                <Button onClick={(event) => { onEdit(record.key, event); }}> Edit </Button>
                            </div>
                    )}
                />
            </Table>
            { editting.valid ?
                props.readOnly ?
                    <EditAccount mode="View" reList={reList} editting={editting}/>
                    :   <EditAccount mode="Edit" reList={reList} editting={editting}/>
                :  props.readOnly ?
                    <div />
                    :   <EditAccount mode="Add"  reList={reList}/>
            }
        </div>
    );
}

AccountMgmt.propTypes = {
    appStateChange: PropTypes.func,
    appState: PropTypes.object,
    readOnly: PropTypes.bool
};
